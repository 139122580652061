import express from "express";
import { getServerHelpers, getUniversalHelpers } from "../../helpers";
import { validateTokenAndEnsureSelfAccountIdMatches } from "../../internal-utils/server-auth";

export async function billing__server__submitClubLead(p: {
  selfAccountId: string;
  clubName: string;
  clubRole: string;
  numberPlayers: string;
  phoneNumber: string;
  additionalInfo: string;
}) {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h, olliePipe } = getUniversalHelpers();
  const { channels, emailTemplateServiceFn } = getServerHelpers();
  const account = await h.Account.getDoc(p.selfAccountId);

  const info = [
    `Name: ${account?.firstName} ${account?.lastName}`,
    `Email: ${account?.email}`,
    `User Info: https://admin.olliesports.com/#/account/${account?.id}`,
    `Club Name: ${p.clubName}`,
    `${p.clubRole ? `Club Role: ${p.clubRole}` : ""}`,
    `${p.numberPlayers ? `Estimated # Players: ${p.numberPlayers}` : ""}`,
    `${p.phoneNumber ? `Phone Number: ${p.phoneNumber}` : ""}`,
    `${p.additionalInfo ? `Additional Info: ${p.additionalInfo}` : ""}`
  ].filter(a => a);

  await emailTemplateServiceFn({
    templateId: "d-d720decde0594625a06de06c2270bd41",
    to: "matt@olliesports.com",
    templateData: {
      subject: `New Club Lead `,
      body: `<div>New club lead submitted. Details:</div>
      <ul>
        <li>${info.join("</li><li>")}</li>
      </ul>
      `
    }
  });

  await channels.leadsChannel(`****New Club Lead****\n${info.join("\n")}`);
  // SERVER_ONLY_TOGGLE
}

billing__server__submitClubLead.auth = async (r: express.Request) => {
  validateTokenAndEnsureSelfAccountIdMatches(r);
};

// i18n certified - complete
