import { BatchTask } from "@ollie-sports/firebase";
import { Coupon, License, TeamPlanCost } from "@ollie-sports/models";
import { ServerThisContext } from "@ollie-sports/react-bifrost";
import express from "express";
import moment from "moment";
import { getServerHelpers, getUniversalHelpers } from "../../helpers";
import { addStripeLicense, renewLicense } from "../../internal-utils/license-utils";
import { formatMoneyValue } from "../../internal-utils/money-utils";
import { validateToken } from "../../internal-utils/server-auth";
import { notifyOfExtremelyImportantError, emitMarketingEvent } from "../../utils";
import { coupon__server__useCoupon } from "../coupon";
import { miscEmails__server__paymentReceiptEmail } from "../miscEmails";
import { couponsFn } from "./helpers";

interface BaseProps {
  teamId: string;
  creditCardToken: string;
  coupon?: Coupon;
  email?: string;
  name: string;
  accountId?: string;
  amountToCharge: number;
  discount?: number;
}

interface NewLicenseProps extends BaseProps {
  type: "new";
}
interface RenewLicenseProps extends BaseProps {
  type: "renew";
  currentLicense: License;
  humanReadableReasonForAction: string;
}

export async function billing__server__proccessTeamPayment(
  this: ServerThisContext,
  p: NewLicenseProps | RenewLicenseProps
): Promise<{ type: "success" } | { type: "error"; errorMsg: string }> {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h, olliePipe } = getUniversalHelpers();
  const {
    channels,
    injectedServerLibraries: { stripe }
  } = getServerHelpers();

  const team = await h.Team.getDoc(p.teamId);
  if (!team) {
    throw new Error(this.translate({ defaultMessage: "Unable to find team. Cannot process team payment" }));
  }

  const couponString =
    p.coupon && p.discount
      ? this.translate(
          { defaultMessage: `coupon {couponId} applied for a savings of {moneyVal}` },
          { couponId: p.coupon.id, moneyVal: formatMoneyValue(p.discount) }
        )
      : "";

  try {
    // Charge the credit card
    let r1 = await stripe.charges.create({
      amount: p.amountToCharge,
      currency: "usd",
      description: `${this.translate({ defaultMessage: "Team Payment" })}${couponString ? ` (${couponString})` : ""}`,
      source: p.creditCardToken
    });

    const now = Date.now();
    if (r1.status === "succeeded") {
      olliePipe.emitEvent({
        type: "analytic-stripe-team-payment-success",
        payload: { stripeId: r1.id, stripeAmount: r1.amount, teamId: p.teamId }
      });
      if (p.coupon && p.discount) {
        olliePipe.emitEvent({
          type: "analytic-stripe-team-payment-coupon-discount",
          payload: {
            stripeId: r1.id,
            stripeAmount: r1.amount,
            teamId: p.teamId,
            couponCode: p.coupon.id,
            discount: p.discount / 100
          }
        });
        coupon__server__useCoupon({
          couponId: p.coupon.id
        });
      }
      let tasks: BatchTask[] = [];
      if (p.type === "renew") {
        tasks = await renewLicense({
          actionPerformedByAccountId: p.accountId ?? "SYSTEM",
          entityId: p.teamId,
          entityType: "team",
          humanReadableReasonForAction: p.humanReadableReasonForAction,
          licenseId: p.currentLicense.id,
          newExpireAtMS: moment(p.currentLicense.expireAtMS).add(1, "year").valueOf(),
          renewalDetails: `Stripe ID: ${r1.id}`,
          couponString
        });
      } else {
        tasks = await addStripeLicense({
          actionPerformedByAccountId: p.accountId ?? "SYSTEM",
          teamId: p.teamId,
          stripeTransactionDetails: JSON.stringify(r1),
          expireAtMS: moment(now).add(1, "year").valueOf(),
          startAtMS: now,
          couponString
        });
      }
      if (p.email && r1.receipt_url) {
        await miscEmails__server__paymentReceiptEmail({
          name: p.name.split(" ")[0],
          email: p.email,
          receiptUrl: r1.receipt_url
        });
      }

      await h._BatchRunner.executeBatch(tasks);

      await Promise.all([
        emitMarketingEvent({
          olliePipe,
          event: {
            type: "TeamSubscription",
            teamId: p.teamId,
            currency: "USD",
            value: p.amountToCharge
          }
        }).catch(e => console.error(e)),
        channels.goodNewsChannel(`:moneybag: Team Payment Processed :moneybag:
Team: ${team.name}
Amount: $${p.amountToCharge / 100}
      `)
      ]);

      return { type: "success" };
    }
    return { type: "error", errorMsg: "" };
  } catch (e) {
    olliePipe.emitEvent({
      type: "stripe-team-payment-error",
      payload: { teamId: p.teamId }
    });
    notifyOfExtremelyImportantError({
      channel: "#important-errors",
      message: `
      ERROR Processing Stripe Payment!!
      Team: ${p.teamId}
      Payload: ${JSON.stringify(p)}
          `
    });
    // @ts-ignore
    return { type: "error", errorMsg: e.raw.message };
  }
  // SERVER_ONLY_TOGGLE
}

billing__server__proccessTeamPayment.auth = async (r: express.Request) => {};
// i18n certified - complete
