import { BatchTask } from "@ollie-sports/firebase";
import { Account, AccountId, License, LicenseId, LICENSE_TYPES } from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";
import * as _ from "lodash";
import { validateToken } from "../../internal-utils/server-auth";
import { refreshInAppRevenueCatLicenseRelationships } from "../../internal-utils/license-utils";

export * from "./billing__proccessTeamPayment";
export * from "./billing__processPaymentToOrg";
export * from "./billing__submitClubLead";

export function billing__universal__getLicense(p: { licenseId: LicenseId }): Promise<License | null> {
  return getUniversalHelpers().ollieFirestoreV2.License.getDoc(p.licenseId);
}

type PotentialFamilyMemberStatus = {
  account: Account;
  status: "already_part_of_host_plan" | "part_of_another_family_plan" | "eligible_to_join_family" | "already_upgraded";
};
export async function billing__server__getPotentialFamilyAccounts(p: {
  hostAccountId: AccountId;
}): Promise<PotentialFamilyMemberStatus[]> {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  let potentialAccountIds: AccountId[] = [];
  const { docs: bundles } = await h.PlayerBundle.query({
    where: [{ managingAccounts: { [p.hostAccountId]: { exists: ["==", true] } } }]
  });

  const result: PotentialFamilyMemberStatus[] = [];

  const hostAccountPrivate = await h.AccountPrivate.getDoc(p.hostAccountId);
  if (!hostAccountPrivate) {
    throw new Error("Unable to find hostAccountPrivate");
  }

  await Promise.all(
    bundles.map(async bundle => {
      if (bundle.managingAccounts) {
        potentialAccountIds.push(...Object.keys(bundle.managingAccounts));
      }
    })
  );

  potentialAccountIds = _.uniq(potentialAccountIds.filter(id => id !== p.hostAccountId));
  const potentialAccountPrivates = await h.AccountPrivate.getDocs(potentialAccountIds);
  await Promise.all(
    potentialAccountPrivates.map(async accountPrivate => {
      if (!accountPrivate) {
        // Missing data
        return;
      } else {
        const account = await h.Account.getDoc(accountPrivate.id);
        if (!account) {
          // Missing data
          return;
        }

        if (!accountPrivate.linkedLicenseId) {
          // No linked license so is eligible
          result.push({ account, status: "eligible_to_join_family" });
        } else {
          const license = await h.License.getDoc(accountPrivate.linkedLicenseId);
          if (!license) {
            // Since the license doesn't exist (bad data state. Delete link to it and they should be eligible to join)
            await h.AccountPrivate.update({ id: accountPrivate.id, doc: { linkedLicenseId: h._MagicDeleteValue } });
            result.push({ account, status: "eligible_to_join_family" });
          }
          if (license?.type === LICENSE_TYPES.account) {
            if (license.accountId === p.hostAccountId) {
              result.push({ account, status: "already_part_of_host_plan" });
            } else {
              if (
                license.accountLicenseType === "in-app-revenue-cat" &&
                license.derivedExternal?.activeRevenueCatEntitlements["premium-family-01"]
              ) {
                result.push({ account, status: "part_of_another_family_plan" });
              } else {
                result.push({ account, status: "already_upgraded" });
              }
            }
          }
        }
      }
    })
  );

  return result;
  // SERVER_ONLY_TOGGLE
}
billing__server__getPotentialFamilyAccounts.auth = async (req: any) => {
  await validateToken(req);
};

const defaultFamilyPlanSize = 4;
export async function billing__server__addAccountIdToFamilyPlan(p: { hostAccountId: AccountId; accountIdToAdd: AccountId }) {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  const hostAccountPrivate = await h.AccountPrivate.getDoc(p.hostAccountId);
  if (!hostAccountPrivate) {
    throw new Error("Unable to addAccountIdToFamilyPlan since cannot find hostAccountPrivate");
  }

  let maxAllowedAccountIdsOnPlan = defaultFamilyPlanSize;
  if (hostAccountPrivate.licenseFamilyShare?.familySizeLimit === undefined) {
    // Set the limit on the account if its never been set
    await h.AccountPrivate.update({
      id: p.hostAccountId,
      doc: { licenseFamilyShare: { familySizeLimit: defaultFamilyPlanSize } }
    });
  } else {
    maxAllowedAccountIdsOnPlan = hostAccountPrivate.licenseFamilyShare.familySizeLimit;
  }

  let numPlansOnPlan = 0;
  if (hostAccountPrivate.licenseFamilyShare?.accountIds) {
    numPlansOnPlan = Object.keys(hostAccountPrivate.licenseFamilyShare.accountIds).length;
  }

  if (maxAllowedAccountIdsOnPlan <= numPlansOnPlan) {
    throw new Error("Insufficient space on the plan. Consider upping familySizeLimit for this account");
  }

  await h.AccountPrivate.update({
    id: p.hostAccountId,
    doc: { licenseFamilyShare: { accountIds: { [p.accountIdToAdd]: true } } }
  });

  await refreshInAppRevenueCatLicenseRelationships({ hostAccountId: p.hostAccountId });
  // SERVER_ONLY_TOGGLE
}
billing__server__addAccountIdToFamilyPlan.auth = async (req: any) => validateToken(req);

export async function billing__server__removeAccountIdFromFamilyPlan(p: {
  hostAccountId: AccountId;
  accountIdToRemove: AccountId;
}) {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  await h.AccountPrivate.update({
    id: p.hostAccountId,
    doc: { licenseFamilyShare: { accountIds: { [p.accountIdToRemove]: h._MagicDeleteValue } } }
  });
  await refreshInAppRevenueCatLicenseRelationships({ hostAccountId: p.hostAccountId });
  // SERVER_ONLY_TOGGLE
}
billing__server__removeAccountIdFromFamilyPlan.auth = async (req: any) => validateToken(req);

export function billing__server__handleRevenueCatWebhook() {
  // SERVER_ONLY_TOGGLE
  // Ignore most of them
  // If they got a renewal or a new plan
  // Check if they have another plan active
  // Any other active plans on android we should cancel
  // If they have a plan on android and ios then log to the error channel saying we likely should contact them and see what is going on
  return { hello: "world" };
  // SERVER_ONLY_TOGGLE
}
billing__server__handleRevenueCatWebhook.auth = async (req: any) => {};

// i18n certified - complete
